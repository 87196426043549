
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import CountriesListByRegion from '@/components/countries-list/CountriesListByRegion.vue';

import { OnboardingStatus } from '@/enums/onboarding-status';
import type { WebLocale } from '@/enums/web-locale';

import type { WebLocales } from '@/constants/web-locales.constant';
import { Region } from '@/interfaces/locale-details';

const Language = namespace('language');
const User = namespace('user');

@Component({
	name: 'CountriesList',
	components: {
		CountriesListByRegion,
	},
	serverCacheKey: ({ activeLocalesList, country }) => {
		return activeLocalesList?.length ? `countries-list-${country}-${activeLocalesList.length}` : false;
	},
})
export default class CountriesList extends Vue {
	Region = Region;

	@Prop() activeLocalesList: WebLocales;
	@Prop() country: string;

	@Language.Action changeWebLocale: (webLocale: WebLocale) => Promise<any>;
	@Language.Action setPersistentWebLocale: (webLocale: WebLocale) => Promise<any>;

	@User.Action saveOnboardingStatus: (partialSettings: OnboardingStatus) => Promise<any>;

	async setCountry(webLocale: WebLocale) {
		await this.changeWebLocale(webLocale);

		await this.setPersistentWebLocale(webLocale);

		this.$router.push({ name: 'app.titles.popular.list' });
	}
}
