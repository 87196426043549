export const sleep = (m = 0) => new Promise(r => setTimeout(r, m));

/**
 * This method is intended to give us a way to track ionic magical dom modifications.
 * In other cases, this method usage should be avoided!
 */
export const waitUntil = async (condition: () => boolean, timeout = 100, maxInterval = 1000) => {
	let interval = 0;
	while (!condition()) {
		if (interval >= maxInterval) {
			throw new Error("Condition hadn't been fulfilled even though interval had expired.");
		}
		await sleep(100);
		interval += timeout;
	}
	return;
};

export const waitInAnimationFrameUntil = (
	condition: () => boolean,
	strict = false,
	duration = 3000,
	start = Date.now(),
	resolve?: () => void,
	reject?: () => void
) => {
	let promise: Promise<void> | undefined;
	if (!resolve) {
		promise = new Promise((rs, rj) => {
			resolve = rs;
			reject = rj;
		});
	}
	const elapsedTime = Date.now() - start;
	if (condition()) {
		resolve!();
	} else {
		if (elapsedTime < duration) {
			requestAnimationFrame(() => waitInAnimationFrameUntil(condition, strict, duration, start, resolve, reject));
		} else {
			if (strict) {
				reject!();
			} else {
				resolve!();
			}
		}
	}

	return promise;
};

export const waitForHydration = (element: HTMLElement, strict = false, duration = 3000) => {
	return waitInAnimationFrameUntil(() => element?.classList.contains('hydrated'), strict, duration);
};

export const waitForRef = (component: Vue, refName: string, strict = false, duration = 3000) => {
	return waitInAnimationFrameUntil(() => !!component.$refs[refName], strict, duration);
};

export class Deferred<T> {
	public promise: Promise<T>;
	public resolveInternal: (value: T) => void;
	private setTimeoutId: number | null = null;
	private timeout: number;
	public resolved = false;

	constructor(options?: { timeout?: number }) {
		this.promise = new Promise<T>(resolve => {
			this.resolve = resolve;
		});
		if (options?.timeout !== undefined) {
			this.timeout = options.timeout;
		}
	}
	resetTimeout() {
		if (this.setTimeoutId) {
			clearTimeout(this.setTimeoutId);
		}
		this.setTimeoutId = setTimeout(this.resolve, this.timeout);
	}
	resolve(value: T): void {
		this.resolved = true;
		this.resolveInternal(value);
	}
}
