import { render, staticRenderFns } from "./GlobalTitles.vue?vue&type=template&id=2e8b6aca&scoped=true"
import script from "./GlobalTitles.vue?vue&type=script&setup=true&lang=ts"
export * from "./GlobalTitles.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./GlobalTitles.vue?vue&type=style&index=0&id=2e8b6aca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e8b6aca",
  null
  
)

export default component.exports