import { WebLocale } from '@/enums/web-locale';
import { TitleDetail } from '@/interfaces/title-details-graphql';

type PromotionProviders = 9 | 119 | 337 | 1773 | 1899;

const promotionProviderIcons: Record<PromotionProviders, string> = {
	9: `${JW_CONFIG.IMAGESCALER_URL}/icon/52449539/s100/amazonprime.webp`,
	119: `${JW_CONFIG.IMAGESCALER_URL}/icon/52449539/s100/amazonprime.webp`,
	337: `${JW_CONFIG.IMAGESCALER_URL}/icon/313118777/s100/disneyplus.webp`,
	1773: `${JW_CONFIG.IMAGESCALER_URL}/icon/300772362/s100/skyshowtime.webp`,
	1899: `${JW_CONFIG.IMAGESCALER_URL}/icon/305458112/s100/max.webp`,
};

interface PromoText {
	/** Set `null` to disable. */
	ribbon: string | null;
	/** Set `null` to disable. */
	offerText: string | null;
	/** Set `null` to disable. */
	longText: string | null;
	/** Optional promotion start date in `YYYY-MM-DD` format. */
	startDate?: ISO8601;
	/** `YYYY-MM-DD` format. */
	endDate: ISO8601;
	/** The ID of the promoted provider. */
	provider: PromotionProviders;
	/** The Url of the promoted provider which overrides the regular offer link. */
	url?: string;
}

/** Normal PromoText config with the Provider Icon URL */
type PromoTextWithIcon = PromoText & { icon: string };

type PromotionCountries = 'BR' | 'CL' | 'CO' | 'DK' | 'ES' | 'FI' | 'MX' | 'NL' | 'NO' | 'PE' | 'PL' | 'SE' | 'US';

const promotions: Record<PromotionCountries, PromoText[]> = {
	BR: [
		{
			ribbon: 'Oferta',
			offerText: '70% de desconto',
			longText: 'Aproveite Max por R$ 8.90',
			startDate: '2024-11-15',
			endDate: '2024-12-02',
			provider: 1899,
			url: 'https://max.prf.hn/click/camref:1101lv2i7',
		},
	],
	CL: [
		{
			ribbon: 'Venta',
			offerText: '70% de descuento',
			longText: 'Aprovecha Max por $1,790/meses',
			startDate: '2024-11-15',
			endDate: '2024-12-02',
			provider: 1899,
			url: 'https://max.prf.hn/click/camref:1101lv2i7',
		},
	],
	CO: [
		{
			ribbon: 'Venta',
			offerText: '70% de descuento',
			longText: 'Aprovecha Max por $5.900/mes',
			startDate: '2024-11-15',
			endDate: '2024-12-02',
			provider: 1899,
			url: 'https://max.prf.hn/click/camref:1101lv2i7',
		},
	],
	DK: [
		{
			ribbon: 'Rabat',
			offerText: 'Halv pris',
			longText: '-50% på Premium for altid',
			startDate: '2024-11-08',
			endDate: '2024-12-09',
			provider: 1773,
		},
	],
	ES: [
		{
			ribbon: 'Promo',
			offerText: '6,49€/mes',
			longText: '4K UHD al 50% descuento',
			startDate: '2024-11-08',
			endDate: '2024-12-09',
			provider: 1773,
		},
	],
	FI: [
		{
			ribbon: '-50%',
			offerText: 'Premium',
			longText: '-50% loppuelämäksi',
			startDate: '2024-11-08',
			endDate: '2024-12-09',
			provider: 1773,
		},
	],
	MX: [
		{
			ribbon: 'Venta',
			offerText: '70% de descuento',
			longText: 'Aprovecha Max por $44.00/mes',
			startDate: '2024-11-15',
			endDate: '2024-12-02',
			provider: 1899,
			url: 'https://max.prf.hn/click/camref:1101lv2i7',
		},
		{
			ribbon: 'OFERTA',
			offerText: 'Prueba Ya!',
			longText: '30 Dias de Prueba Gratuita',
			startDate: '2024-11-22',
			endDate: '2024-12-06',
			provider: 119,
		},
	],
	NL: [
		{
			ribbon: 'Actie',
			offerText: 'Premium',
			longText: '50% korting voor altijd',
			startDate: '2024-11-08',
			endDate: '2024-12-09',
			provider: 1773,
		},
	],
	NO: [
		{
			ribbon: '-50%',
			offerText: 'Premium',
			longText: 'Få 50% rabatt for alltid ',
			startDate: '2024-11-08',
			endDate: '2024-12-09',
			provider: 1773,
		},
	],
	PE: [
		{
			ribbon: 'Venta',
			offerText: '70% de descuento',
			longText: 'Aprovecha Max porS/. 6,90/mes',
			startDate: '2024-11-15',
			endDate: '2024-12-02',
			provider: 1899,
			url: 'https://max.prf.hn/click/camref:1101lv2i7',
		},
	],
	PL: [
		{
			ribbon: 'Rabat',
			offerText: 'Premium',
			longText: 'Nowy Plan 50% taniej!',
			startDate: '2024-11-08',
			endDate: '2024-12-09',
			provider: 1773,
		},
	],
	SE: [
		{
			ribbon: '-50%',
			offerText: 'Premium',
			longText: 'Halva priset för alltid ',
			startDate: '2024-11-08',
			endDate: '2024-12-09',
			provider: 1773,
		},
	],
	US: [
		{
			ribbon: 'OFFER',
			offerText: 'FREE TRIAL',
			longText: '30-DAY FREE TRIAL',
			startDate: '2024-09-20',
			endDate: '2024-11-30',
			provider: 9,
		},
	],
};

export function getPromoText(
	country: string,
	offers: TitleDetail['offers'] = [],
	currentDate: Date = new Date()
): PromoTextWithIcon | null {
	const texts = promotions[country as PromotionCountries];
	if (!texts || texts.length === 0 || offers.length === 0) return null;

	const promoText = texts.find(promo => offers.some(offer => offer.package.packageId === promo.provider));

	if (promoText == null) return null;
	// Checking if we're within the promo dates
	if (promoText.startDate && +currentDate < new Date(promoText.startDate).setUTCHours(0, 0, 0, 0)) return null;
	if (promoText.endDate && +currentDate > new Date(promoText.endDate).setUTCHours(23, 59, 59, 999)) return null;

	return { ...promoText, icon: promotionProviderIcons[promoText.provider] };
}

// Disney External Pixel Tracking
const oceaniaLocales = new Set([WebLocale.au, WebLocale.nz]);
const latamLocales = new Set([
	WebLocale.br,
	WebLocale.mx,
	WebLocale.ar,
	WebLocale.cl,
	WebLocale.co,
	WebLocale.pe,
	WebLocale.uy,
	WebLocale.ec,
	WebLocale.pa,
	WebLocale.cr,
	WebLocale.py,
	WebLocale.bo,
	WebLocale.gt,
	WebLocale.ni,
	WebLocale.do,
	WebLocale.sv,
	WebLocale.hn,
	WebLocale.ve,
]);

export function getDisneyImpressionUrl(packageId: number | null | undefined, webLocale: WebLocale) {
	if (packageId === 337 /* Disney+ */) {
		if (oceaniaLocales.has(webLocale)) return 'https://imp.pxf.io/i/1206980/1833790/9358';

		if (latamLocales.has(webLocale)) return 'https://imp.pxf.io/i/1206980/1848147/9358';
	}

	if (packageId === 619 /* Star+ */) {
		if (latamLocales.has(webLocale)) return 'https://imp.pxf.io/i/1206980/1584316/13591';
	}

	return null;
}

// TYPES
type digits = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
type day = `${'0'}${Exclude<digits, '0'>}` | `${'1' | '2'}${digits}` | '30' | '31';
type month = `${'0'}${Exclude<digits, '0'>}` | '10' | '11' | '12';
type year = `20${digits}${digits}`;
type ISO8601 = `${year}-${month}-${day}`;
