import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetGlobalNewTitlesQueryVariables = Types.Exact<{
	country: Types.Scalars['Country']['input'];
	first: Types.Scalars['Int']['input'];
	filter?: Types.InputMaybe<Types.TitleFilter>;
	language: Types.Scalars['Language']['input'];
	date?: Types.InputMaybe<Types.Scalars['Date']['input']>;
	pageType: Types.NewPageType;
}>;

export type GetGlobalNewTitlesQuery = {
	__typename?: 'Query';
	newTitles: {
		__typename?: 'NewTitlesConnection';
		edges?: Array<{
			__typename?: 'NewTitlesEdge';
			node:
				| {
						__typename: 'Movie';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'MovieContent';
							fullPath: string;
							posterUrl?: string | null;
							title: string;
							isReleased: boolean;
						};
				  }
				| {
						__typename: 'Season';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						show: { __typename: 'Show'; id: string; objectId: number; objectType: Types.ObjectType };
						content: {
							__typename?: 'SeasonContent';
							fullPath: string;
							posterUrl?: string | null;
							title: string;
							isReleased: boolean;
						};
				  };
		}> | null;
	};
};

export const GetGlobalNewTitlesDocument = gql`
	query GetGlobalNewTitles(
		$country: Country!
		$first: Int!
		$filter: TitleFilter
		$language: Language!
		$date: Date
		$pageType: NewPageType!
	) {
		newTitles(country: $country, first: $first, filter: $filter, date: $date, pageType: $pageType) {
			edges {
				node {
					__typename
					id
					objectId
					objectType
					content(country: $country, language: $language) {
						fullPath
						posterUrl
						title
						isReleased
					}
					... on Season {
						show {
							__typename
							id
							objectId
							objectType
						}
					}
				}
			}
		}
	}
`;
