
import Vue from 'vue';
import VueObserveVisibility from 'vue-observe-visibility';
import { Component, Prop, Ref } from 'vue-property-decorator';

import TitleScoreList from '@/components/TitleScoreList.vue';
import PictureComp from '@/components/picture/PictureComp.vue';
import PosterGraphql from '@/components/poster/PosterGraphql.vue';
import BasicButton from '@/ui-components/BasicButton.vue';
import TitleDetailLink from '@/components/nav/links/TitleDetailLink.vue';
import { captureMessageForSentry } from '@/helpers/sentry-helper';

import {
	GetGlobalLargeSpotlightTitlesQuery,
	GetGlobalLargeSpotlightTitlesDocument,
	GetGlobalLargeSpotlightTitlesQueryVariables,
} from '@/pages/graphql/queries/GetGlobalLargeSpotlightTitles.query';

import { waitForRef } from '@/helpers/async-helper';
import { SnowplowContext } from '@/helpers/tracking/providers';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';

Vue.use(VueObserveVisibility);

type GlobalLargeSpotlightTitles = NonNullable<GetGlobalLargeSpotlightTitlesQuery['popularTitles']['edges']>;

@Component({
	name: 'GlobalLargeSpotlight',
	components: {
		PosterGraphql,
		TitleScoreList,
		BasicButton,
		PictureComp,
		TitleDetailLink,
	},
	apollo: {
		globalLargeSpotlightTitles: {
			query: GetGlobalLargeSpotlightTitlesDocument,
			variables(): GetGlobalLargeSpotlightTitlesQueryVariables {
				return this.queryVariables;
			},
			update(data: GetGlobalLargeSpotlightTitlesQuery) {
				return data.popularTitles.edges ?? [];
			},
			errorPolicy: 'all',
			error(error) {
				captureMessageForSentry(
					'[GraphQL Get Global Large Spotlight Titles Data]:',
					{ error: error, where: 'Component: GlobalLargeSpotlight' },
					'error'
				);
			},
		},
	},
})
export default class GlobalLargeSpotlight extends Vue {
	@Ref() slider: HTMLIonSlidesElement;

	@Prop() language: string;
	@Prop() country: string;

	globalLargeSpotlightTitles: GlobalLargeSpotlightTitles = [];

	slideOpts = {
		initialSlide: 0,
		loop: true,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		grabCursor: true,
	};

	get queryVariables() {
		return {
			country: this.country,
			language: this.language,
			first: 7,
		};
	}

	getBackdrop(backdrops: { backdropUrl: string }[]) {
		return backdrops[0]?.backdropUrl || '';
	}

	getHoursAndMinutes(minutes: number) {
		const hrs = Math.floor(minutes / 60);
		const mins = minutes % 60;

		return `${hrs}h ${mins}m`;
	}

	async mounted() {
		await waitForRef(this, 'slider');
		this.slider.options = this.slideOpts;
	}

	startAutoplay(isVisible: boolean) {
		if (isVisible) {
			this.slider?.startAutoplay();
		} else {
			this.slider?.stopAutoplay();
		}
	}

	next() {
		this.slider?.slideNext();
	}

	previous() {
		this.slider?.slidePrev();
	}

	handleMoreInfoClick(title: any, contexts?: SnowplowContext[]) {
		const titleContext = TrackingHelper.getTitleContextGraphql(
			title.node.objectId,
			title.node.objectType,
			title?.node?.content?.seasonNumber
		);
		const additionalContexts: SnowplowContext[] = (contexts || []).concat([titleContext]);

		TrackingHelper.trackEvent(
			'userinteraction',
			{
				action: 'more_info_clicked',
				label: 'featured',
			},
			additionalContexts
		);
	}
}
