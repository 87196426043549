
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import type { Locale } from '@/helpers/locale-helper';
import type { Region } from '@/interfaces/locale-details';

import { WEB_LOCALES_BY_REGION } from '@/constants/web-locales.constant';

const Language = namespace('language');

@Component({ name: 'CountriesListByRegion' })
export default class CountriesListByRegion extends Vue {
	@Prop({ required: true }) region: Region;
	@Prop({ type: Number, default: 1 }) cols: number;

	@Language.Getter activeLocalesList: Locale[];

	get regionCountries() {
		const countries = WEB_LOCALES_BY_REGION[this.region]
			.filter(locale => locale in this.activeLocalesList)
			.map(locale => ({ locale, name: this.$t(`WEBAPP_COUNTRY_${locale.toUpperCase()}`) as string }));

		countries.sort((a, b) => a.name.localeCompare(b.name));

		return countries;
	}

	get countriesByCol() {
		const itemsPerCol = Math.ceil(this.regionCountries.length / this.cols);

		return Array.from({ length: this.cols }, (_, i) => i * itemsPerCol).map(begin =>
			this.regionCountries.slice(begin, begin + itemsPerCol)
		);
	}
}
