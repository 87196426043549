import { TrackingHelper } from '@/helpers/tracking/tracking-helper';

import type { NewProviderResponse } from '@/interfaces/responses/new';
import type { PopularResponse } from '@/interfaces/responses/popular';
import type { NewTitle, PopularTitle, Title } from '@/interfaces/titles';

import { ObjectType, Show, TitleListType } from '@/@types/graphql-types';
import { SnowplowContext } from '@/helpers/tracking/providers/snowplow-contexts';

import { TitleListLayout } from '@/enums/title-list-layout';
import { TitleDetail } from '@/interfaces/title-details-graphql';
import { PosterTitle } from '@/interfaces/components/poster/PosterGraphql';

export interface TitleStoreStateList extends PopularResponse {
	listLayout: TitleListLayout.CARD | TitleListLayout.GRID;
}

export interface TitleEventPayload {
	title: PopularTitle | NewTitle;
	index?: number;
	date?: string;
	provider?: NewProviderResponse;
}

export interface TitleEventPayloadGraphql {
	title: {
		objectType: ObjectType;
		objectId: number;
		content?: {
			seasonNumber: number;
		};
		show?: {
			objectType: ObjectType;
			objectId: number;
		};
	};
	index?: number;
	date?: string;
	providerId?: number;
	label?: string;
}

/** EXPORT */
const decorateFullPath = (response: any) => {
	return {
		...response.data,
		items: response.data.items?.map((item: any) => {
			return {
				...item,
				full_path: item.full_path,
			};
		}),
	};
};

const formatRuntime = (runtime: number) => {
	const minutes = runtime % 60;
	const hours = (runtime - minutes) / 60;

	const result = {
		translationKey: 'WEBAPP_DURATION_MINUTES',
		minutes,
		hours,
	};

	if (runtime >= 60) {
		result.translationKey = 'WEBAPP_DURATION';
	}

	return result;
};

/** Look for a monetized trailer clip if available. */
const getBackdropClips = (title?: TitleDetail) => {
	if (!title) return [];

	const clipsContainer = title.__typename === 'Season' ? title.show : title;

	let clips = clipsContainer.content.dailymotionClips;
	if (!clips || clips.length === 0) clips = clipsContainer.fallBackClips.dailymotionClips;
	if (!clips || clips.length === 0) clips = clipsContainer.content.videobusterClips;
	if (!clips || clips.length === 0) clips = clipsContainer.fallBackClips.videobusterClips;
	// Show has no trailers, try to get it from season
	if ((!clips || clips.length === 0) && title.__typename === 'Show')
		clips =
			title?.seasons.find(season => season.fallBackClips.dailymotionClips.length > 0)?.fallBackClips
				.dailymotionClips || [];
	if ((!clips || clips.length === 0) && title.__typename === 'Show')
		clips =
			title?.seasons.find(season => season.fallBackClips.videobusterClips.length > 0)?.fallBackClips
				.videobusterClips || [];

	// No monetized trailers at all, final fallback to YouTube
	if (!clips || clips.length === 0) clips = title.content.clips;
	if (!clips || clips.length === 0) clips = clipsContainer.fallBackClips.clips;
	if ((!clips || clips.length === 0) && title.__typename === 'Show')
		clips = title?.seasons.find(season => season.fallBackClips.clips.length > 0)?.fallBackClips.clips || [];

	return clips ?? [];
};

const getTitleSlug = (title: NewTitle | Title) => {
	const fullPath = title.full_path?.match(/([^\/]*)$/);
	return fullPath && fullPath[0] ? fullPath[0] : 'Poster';
};

/**
 * track a title related event with all needed contexts
 *
 * @param {string} evtName snowplow category
 * @param {TitleEventPayload} payload data required
 * @param {string} evtProperty snowplow event se_property
 *
 * @emits trackEvent "userinteraction"
 */
const trackTitleEvent = (
	evtName: string,
	payload: TitleEventPayload,
	evtProperty: string,
	contexts?: SnowplowContext[]
) => {
	const { title, index, date = '', provider = {} } = payload;
	const { provider_id = '' } = provider as NewProviderResponse;

	// if we have a date/provider_id in the payload we use them as label for BI, ortherwise we use title
	const label = `${date},${provider_id}`;

	const properties = {
		action: evtName,
		label: label,
		value: index,
		property: evtProperty,
	};

	// we attach filter context and title context (also Development)
	const titleContext = TrackingHelper.getTitleContext(title);
	const additionalContexts: SnowplowContext[] = (contexts || []).concat([titleContext]);
	TrackingHelper.trackEvent('userinteraction', properties, additionalContexts);
};

const trackTitleEventGraphql = (
	evtName: string,
	payload: TitleEventPayloadGraphql,
	evtProperty: string,
	contexts?: SnowplowContext[]
) => {
	const { title, index, date = '', providerId = '', label = '' } = payload;

	// if we have a date/provider_id in the payload we use them as label for BI, ortherwise we use title
	const trackingLabel = label || `${date},${providerId}`;

	const properties = {
		action: evtName,
		label: trackingLabel,
		value: index,
		property: evtProperty,
	};

	let { objectId, objectType } = title;

	if (title?.show) {
		objectId = title.show.objectId;
		objectType = title.show.objectType;
	}
	// we attach filter context and title context (also Development)
	const titleContext = TrackingHelper.getTitleContextGraphql(
		objectId,
		objectType,
		title?.content?.seasonNumber,
		undefined,
		undefined,
		index
	);
	const additionalContexts: SnowplowContext[] = (contexts || []).concat([titleContext]);
	TrackingHelper.trackEvent('userinteraction', properties, additionalContexts);
};

function isTitleSeason(node?: PosterTitle): node is PosterTitle & { __typename: 'Season' } {
	return node?.__typename === 'Season' && !!node.show;
}

function isTitleShow(node?: PosterTitle): node is PosterTitle & { __typename: 'Show' } & Show {
	return node?.__typename === 'Show';
}

function isTitleInList(listName: TitleListType, title: PosterTitle) {
	switch (listName) {
		case TitleListType.WatchNext:
			const _title = title.__typename === 'Season' ? title.show : title;
			return !!_title?.watchlistEntryV2?.createdAt;
		case TitleListType.Likelist:
			return !!title?.likelistEntry?.createdAt;
		case TitleListType.Dislikelist:
			return !!title?.dislikelistEntry?.createdAt;
		case TitleListType.CaughtUp:
			return title?.__typename === 'Movie'
				? !!title?.seenlistEntry?.createdAt
				: title?.seenState?.progress === 100;
		default:
			return false;
	}
}

export {
	decorateFullPath,
	formatRuntime,
	getBackdropClips,
	getTitleSlug,
	trackTitleEvent,
	trackTitleEventGraphql,
	isTitleInList,
	isTitleSeason,
	isTitleShow,
};
